
.about {
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: 80vh;
}

.about .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.about h1 {
    font-family: Calibri;
    text-align: center;
    color: #284243;
}

.about p {
    max-width: 80%;
    font-family: Calibri;
    font-size: 2.3vh;
    text-align: justify;
}