.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  margin: 0;
}

body {
  overflow: hidden;
  background-color: #f5f5f5 !important;
}

.copyright {
  font-family: Roboto;
  text-align: center;
}

.info-icon {
  position: absolute;
  right: 1.5vh;
  top: -1vh;
  cursor: pointer;
  z-index: 9;
  color: #284243
}

.hide {
  display: none;
}

.dimmer {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.baloon {
    margin: 0 auto; 
    background: #284243;
    font-family: 'open sans';
    font-size: 1.2em;
    line-height: 1.6em;  
    border-radius: 15px;
    width: 20vw;
    height: auto;
    color: #fff;
    padding: 20px;
    position: absolute;
    bottom: 3%;
    right: 2%;
    z-index: 999;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.12);
}

.grid-input {
  position: relative;
}

.MuiDrawer-paperAnchorDockedLeft {
  background-color: #284243 !important;
  overflow-y: scroll !important;
}

#leftMenu nav {
  height: 100%;
}

#leftMenu .MuiListItemIcon-root,
#leftMenu .MuiListSubheader-root,
#leftMenu .MuiButtonBase-root,
#leftMenu .MuiButtonBase-root p {
  color: white !important;
}

#topBar .MuiFormLabel-root,
#topBar #property,
#topBar #project {
  color: white !important;
}

#InConstruction {
  display: block;
  text-align: center;
}

#InConstruction img {
  width: 70%;
}

.empty {
  width: 15%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.emptyMessage {
  color: #284243;
  text-align: center;
  font-size: 3vh;
  font-family: "Khmer MN";
}

.backdrop {
  z-index: 9999 !important;
  color: #fff;
}

#FireLoading {
  display: block;
  text-align: center;
  height: 20vh;
}

#Loading {
  display: block;
  text-align: center;
  height: 50vh;
}

#Loading img {
  width: 25%;
}

#FireLoading img {
  width: 20%;
}

@media print {
  h1 {
    display: none;
  }

  h2 {
    font-size: 16px !important;
  }

  button {
    display: none !important;
  }

  main {
    overflow-y: hidden !important;
    height: 200% !important;
    padding: 2%;
  }

  .MuiPaper-root {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  b,
  span {
    font-size: 12px;
  }
}
